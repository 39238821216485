import styled from 'styled-components';
import { StyledStickySection } from 'common/styles';

export const contentMaxWidth = '450px';

export const StyledLogoBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.space[2]}px;
`;

export const StyledLinksWrapper = styled.div`
  padding: ${({ theme }) => `${theme.space[8]}px 0 0`};
  width: 100%;
  height: 100%;
  @media (max-width: ${props => props.theme.screenBreakpoints.large}) {
    padding: ${({ theme }) => ` ${theme.space[8]}px ${theme.space[8]}px 0`};
  }
`;

export const StyledFixedWrapper = styled(StyledStickySection)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-top: 1px solid ${({ theme }) => theme.color.border};
  padding: ${({ theme }) => `${theme.space[6]}px ${theme.space[6]}px 0`};
`;

export const StyledOrderFastPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  @media (min-width: calc(${props => props.theme.screenBreakpoints.large} + 0.2px)) {
    max-width: 375px;
  }
`;

export const StyledPageWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  align-items: center;
`;

export const StyledLangButton = styled.div<{ isArabic: boolean }>`
  position: sticky;
  top: 28px;
  width: 100%;
  ${({ isArabic }) => (isArabic ? 'margin-left: 30px; direction: ltr;' : 'margin-right: 30px; direction: rtl;')}
`;

export const StyledOrderfastLandLoading = styled.div`
  width: 100%;
  @media (min-width: calc(${props => props.theme.screenBreakpoints.large} + 0.2px)) {
    max-width: 375px;
  }
`;

export const StyleOrderButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media (min-width: calc(${props => props.theme.screenBreakpoints.large} + 0.2px)) {
    width: 375px;
  }
`;
